/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lazysizes@5.1.2/lazysizes.min.js
 * - /npm/lazysizes@5.1.2/plugins/aspectratio/ls.aspectratio.min.js
 * - /npm/swiper@8.4.4/swiper-bundle.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
